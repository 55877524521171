import React from "react"
import styled from "styled-components"
import { useCookies } from "react-cookie"
import { graphql, useStaticQuery } from "gatsby"

import { maxD } from "../assets/styles/helpers/devices"

import Layout from "../assets/styles/layout"
import CallToAction from "../components/global/CallToAction"
import Footer from "../components/global/Footer"

import {
  FooterContainer,
  LineWrapper,
  ShadowBoxContainer,
} from "../components/global/common/containers"
import { SecondaryTitle } from "../components/global/common/text"

import DefaultHeader from "../components/global/header/DefaultHeader"

const ACFQuery = graphql`
  query {
    wp {
      page404 {
        en {
          title404
          firstText404
          secondText404
        }
        pl {
          title404Pl
          firstText404Pl
          secondText404Pl
        }
      }
    }
  }
`

const NotFoundShadowBoxContainer = styled(ShadowBoxContainer)`
  --green-box-width: 540px;

  padding: 0 var(--section-spacing) 0
    calc(var(--green-box-width) + var(--section-spacing));

  @media ${maxD.laptopL} {
    --green-box-width: 350px;
  }

  @media ${maxD.laptop} {
    --green-box-width: 0;
  }

  &::before {
    width: var(--green-box-width);
    height: 100%;

    left: 0;
    top: 0;
  }
`

const FirstText = styled(SecondaryTitle)`
  padding: var(--section-spacing) 0 var(--section-spacing);

  @media ${maxD.tabletL} {
    padding: calc(var(--section-spacing) * 0.8) 0
      calc(var(--section-spacing) * 0.6);
  }
`

const SecondText = styled(SecondaryTitle)`
  padding: 0 var(--section-spacing) calc(var(--section-spacing) * 1.4) 0;

  @media ${maxD.tabletL} {
    padding: 0 0 calc(var(--section-spacing) * 0.8);
  }
`

const NotFound = () => {
  const [cookies] = useCookies(["language"]);

  const acfData = useStaticQuery(ACFQuery)
  const enData = acfData.wp.page404.en
  const plData = acfData.wp.page404.pl

  let title, firstText, secondText

  if (cookies.language === "en") {
    title = enData.title404
    firstText = enData.firstText404
    secondText = enData.secondText404
  } else {
    title = plData.title404Pl
    firstText = plData.firstText404Pl
    secondText = plData.secondText404Pl
  }

  return (
    <Layout>
      <DefaultHeader title={title} />
      <LineWrapper as="article">
        <NotFoundShadowBoxContainer>
          <FirstText dangerouslySetInnerHTML={{ __html: firstText }} />
          <SecondText dangerouslySetInnerHTML={{ __html: secondText }} />
        </NotFoundShadowBoxContainer>
      </LineWrapper>
      <FooterContainer>
        <CallToAction />
        <Footer />
      </FooterContainer>
    </Layout>
  )
}

export default NotFound
